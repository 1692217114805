<template lang="html">
    <!-- 账号 新建 弹出 -->
    <el-dialog :visible="personnelVisible" :title="title" :modal-append-to-body="true" :append-to-body="true" :close-on-click-modal="false" width="500px" @close="close()">
		<div v-if="type=='edit'">
			<el-form  ref="formAuth" :model="formData" label-position="top" :label-width="formLabelWidth" inline size="small" :show-message="false" :status-icon="true" class="alertForm">
				<el-form-item label="姓名" prop="realname" required style="width:100%">
					<el-input v-model="formData.realname" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="部门" prop="department_id" required style="width:100%">
					<el-select v-model="formData.department_id" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,index) in departmentList" :label="item.name" :value="parseInt(item.id)" :key="index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="岗位" prop="position_id" required style="width:100%">
					<el-select v-model="formData.position_id" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,index) in positionList" :label="item.name" :value="parseInt(item.id)" :key="index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="手机号" prop="mobile" style="width:100%">
					<el-input v-model="formData.mobile" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="是否管理员" prop="admin_is" required style="width:100%">
					<el-select v-model="formData.admin_is" placeholder="请选择" style="width:100%">
						<el-option label="是" :value="parseInt(1)"></el-option>
						<el-option label="否" :value="parseInt(0)"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer fr">
				<el-button size="small" @click="close()">取 消</el-button>
				<el-button size="small" type="primary" @click="submit()">确 定</el-button>
			</div>
		</div>
		<div v-else>
			<el-form  ref="formAuth2" :model="formData" label-position="top" :label-width="formLabelWidth" inline size="small" :show-message="false" :status-icon="true" class="alertForm">
				<el-form-item label="上级" prop="parent_id" required style="width:100%">
					<el-select v-model="formData.parent_id" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,index) in userlist" :label="item.realname" :value="item.id" :key="index">
							<span style="float: left">{{ item.realname }}</span>
      						<span style="float: right; color: #8492a6; font-size: 13px">{{ item.mobile }}</span>
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer fr">
				<el-button size="small" @click="close()">取 消</el-button>
				<el-button size="small" type="primary" @click="submit2()">确 定</el-button>
			</div>
		</div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            formData: {}, //提交数据
            formLabelWidth: '80px', //弹出form label 宽
            personnelVisible: false, //显隐
			departmentList:[],
			positionList:[],
			userlist:[]
        }
    },
    props: [
        'title',
		'type'
    ],
	mounted(){
		this.getSelect('部门');
		this.getSelect('岗位');
		this.getUser()
	},
    methods: {
        close: function() {
            this.personnelVisible = false;
			if(this.type=='edit'){
				this.$refs.formAuth.resetFields();
			}else{
				this.$refs.formAuth2.resetFields();
			}
            this.$emit('close');
        },
        open: function() {
            this.personnelVisible = true;
        },
        success: function() {
            this.$emit('success');
        },
		getUser(){
			this.http.post('/admin.user/index',{row:99999,curr:1}).then(re=>{this.userlist=re.list})
		},
		getSelect(i) {
            let url = i == '部门' ? '/admin.department/index' : '/admin.position/index'
            this.http.post(url).then((re) => {
				if(i == '部门'){
					this.departmentList = re
				}else{
					this.positionList=re
				}
            })
        },

        submit: function() {
            // 验证
            this.$refs['formAuth'].validate((valid) => {
                if (valid) {
                    // this.formData.password=this.formData.password?this.$md5(this.formData.password):'';
                    let url='/admin.user/save';
                    this.http.post(url, this.formData).then(() => {
                        this.close();
                        this.success(); //父页面刷子你列表
                        this.formData = {}; //清空
                        this.$message({
                            message: '提交成功!',
                            type: 'success'
                        });
                    });
                } else {
                    this.$message.error('必填选项不得为空');
                    console.log('error submit!!');
                    return false;
                }
            });
        },
		submit2(){
			this.$refs['formAuth2'].validate((valid) => {
                if (valid) {
                    // this.formData.password=this.formData.password?this.$md5(this.formData.password):'';
                    let url='/admin.user/parentId';
                    this.http.post(url, this.formData).then(() => {
                        this.close();
                        this.success(); //父页面刷子你列表
                        this.formData = {}; //清空
                        this.$message({
                            message: '提交成功!',
                            type: 'success'
                        });
                    });
                } else {
                    this.$message.error('必填选项不得为空');
                    console.log('error submit!!');
                    return false;
                }
            });
		}
    }
}
</script>
