<template lang="">
<div class="settingContent">
    <div class="handleBox flex flex-jc-sb flex-ai-c">
        <h3 style="">{{$route.name}}</h3>
    </div>

    <div class="contentBox" style="width:100%">
        <el-table :data="list" row-key="id" default-expand-all :tree-props="{children: 'son', hasChildren: 'hasChildren'}" height="100%">
            <el-table-column prop="realname" label="姓名">
            </el-table-column>
            <el-table-column prop="ext_department_name" label="部门">
            </el-table-column>
			<el-table-column prop="ext_position_name" label="岗位">
            </el-table-column>
			<el-table-column prop="mobile" label="手机号">
            </el-table-column>
			<el-table-column label="启用/禁用" align="center">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" @change="setSwitch( scope.row.id , scope.row.status )"></el-switch>
                </template>
            </el-table-column>
			<el-table-column label="操作" align="center">
				<template slot-scope="scope">
					<el-button @click="handleEdit(scope.row)" type="text">编辑</el-button>
					<el-button @click="handleDel(scope.row)" type="text">删除</el-button>
					<el-button @click="handleParent(scope.row)" :disabled="scope.row.parent_id==0" type="text">修改上级</el-button>
					<el-button @click="handleShow(scope.row)" type="text">下级注册码</el-button>
				</template>
			</el-table-column>
        </el-table>
    </div>

	<relationshipAdd :type="type" ref="relationshipAdd" @success="getList()" :title="title"></relationshipAdd>

	<!-- 图片放大查看 -->
    <el-dialog title="图片查看" :visible.sync="dialogVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="350px">
        <img height="310px" :src="dialogImageUrl" alt="" style="display:block;margin:0 auto">
    </el-dialog>
</div>
</template>
<script>
import relationshipAdd from "../../components/relationshipAdd.vue"; //relationship组件
export default {
	components:{
		relationshipAdd
	},
    data() {
        return {
			title:'新建',
			list:[],
			dialogVisible: false, //照片
			dialogImageUrl: '', //照片
			type:'edit'
        };
    },
	mounted(){
		this.getList()
	},
    methods: {
        handleEdit(row){
			this.type='edit';
			this.title= row?'编辑':'新建';
			this.$refs.relationshipAdd.open();
			this.$refs.relationshipAdd.formData={
				id:row.id,
				realname:row.realname,
				department_id:Number(row.department_id)==0?'':Number(row.department_id),
				position_id:Number(row.position_id)==0?'':Number(row.position_id),
				admin_is:Number(row.admin_is),
				mobile:row.mobile,
			};
		},
		handleParent(row){
			this.type='parent';
			this.title= '修改上级';
			this.$refs.relationshipAdd.open();
			this.$refs.relationshipAdd.formData={
				id:row.id,
				parent_id:Number(row.parent_id),
			};
		},
		getList(){
			this.http.post('/admin.user/indexTree').then(re=>{
				this.list=re
			})
		},
		//设置账号禁用和开启
        setSwitch: function(id, _switch) {
            var api = "/admin.user/status";
            this.http.post(api, {
                id,
                status: _switch,
            }).then(() => {
                this.$message({
                    message: '设置成功',
                    type: 'success'
                });
            });
        },
		handleShow(row){
			this.http.post('/admin.user/shareImg',{
				user_id:row.id
			}).then(re=>{
				this.dialogVisible=true;
				this.dialogImageUrl=this.domain+re
			})
		},
		handleDel(row){
			this.$confirm('确定删除选中行吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
				let url ='/admin.user/delete'
                this.http.post(url, {
                    id: row.id
                }).then(re => {
                    this.getList();
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
            })
		}
    },
};
</script>
